import { redirect } from 'react-router-dom';
import { login } from '../../services/auth';

async function action({ request }) {
  const formData = await request.formData();
  const values = Object.fromEntries(formData);
  let res;

  try {
    res = await login({
      email: values.email,
      password: values.password,
    });
  } catch (error) {
    return {
      error,
    };
  }

  if (res?.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
    const url = res.nextStep.totpSetupDetails.getSetupUri('After My Death');
    const secret = res.nextStep.totpSetupDetails.sharedSecret;

    return redirect(`/mfa?status=setup&url=${encodeURIComponent(url)}&secret=${secret}`);
  }

  if (res?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
    return redirect('/mfa');
  }

  return res;
}

export default action;
