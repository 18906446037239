import { redirect } from 'react-router-dom';
import { register, autoLogin } from '../../services/auth';

async function action({ request }) {
  const formData = await request.formData();
  const values = Object.fromEntries(formData);
  let responseRegister;
  let responseAutoLogin;

  try {
    responseRegister = await register({
      email: values.email,
      password: values.password,
    });
  } catch (error) {
    return {
      error,
    };
  }

  if (responseRegister?.nextStep?.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
    try {
      responseAutoLogin = await autoLogin();
    } catch (error) {
      return {
        error,
      };
    }
  }

  if (responseAutoLogin?.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
    const url = responseAutoLogin.nextStep.totpSetupDetails.getSetupUri('After My Death');
    const secret = responseAutoLogin.nextStep.totpSetupDetails.sharedSecret;

    return redirect(`/mfa?status=setup&url=${encodeURIComponent(url)}&secret=${secret}`);
  }

  if (responseAutoLogin?.nextStep?.signInStep === 'DONE') {
    return redirect(import.meta.env.VITE_DOMAIN_DASHBOARD);
  }

  return responseAutoLogin;
}

export default action;
