import {
  signIn,
  signUp,
  autoSignIn,
  confirmSignIn,
} from 'aws-amplify/auth';

// eslint-disable-next-line import/prefer-default-export
export async function register({ email, password }) {
  const result = await signUp({
    username: email,
    password,
    options: {
      userAttributes: {
        email,
      },
      autoSignIn: true,
    },
  });

  return result;
}

export async function autoLogin() {
  return autoSignIn();
}

export async function login({ email, password }) {
  const result = await signIn({
    username: email,
    password,
  });

  return result;
}

export async function confirmLogin({ code }) {
  const result = await confirmSignIn({
    challengeResponse: code,
  });

  return result;
}
