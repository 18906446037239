import { redirect } from 'react-router-dom';
import { confirmLogin } from '../../services/auth';

async function action({ request }) {
  const formData = await request.formData();
  const values = Object.fromEntries(formData);
  let responseConfirm;

  try {
    responseConfirm = await confirmLogin({
      code: values.totp,
    });
  } catch (error) {
    switch (error.name) {
      case 'EnableSoftwareTokenMFAException':
      case 'InvalidParameterException':
        return {
          totp: 'It looks like you entered an invalid code. Please try again.',
        };
      case 'SignInException':
        // This usually means the page was reloaded and AWS Amplify lost state.
        // The only solution is to sign in again.
        return redirect('/login');
      default:
        return {
          totp: 'Something went wrong. Please try again.',
        };
    }
  }

  if (responseConfirm?.nextStep?.signInStep === 'DONE') {
    return redirect(import.meta.env.VITE_DOMAIN_DASHBOARD);
  }

  return {
    totp: 'Unexpected login confirmation response.',
  };
}

export default action;
