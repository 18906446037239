import React from 'react';
import { Form, useActionData, useSearchParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export { default as mfaAction } from './action';

function RouteMFA() {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') ?? 'login';
  const setupUrl = searchParams.get('url');
  const error = useActionData();

  return (
    <>
      <h1>After My Death - {status === 'setup' ? 'Set up MFA' : 'MFA'}</h1>
      <Stack spacing={4}>
        {setupUrl !== null ? <QRCode value={setupUrl} /> : null}
        <Form method="post">
          <Stack spacing={2}>
            {error?.totp !== undefined ? (
              <Alert severity="error">{error.totp}</Alert>
            ) : null}
            <TextField
              required
              id="totp"
              label="Code"
              name="totp"
              autoComplete="one-time-code"
              error={error?.totp !== undefined}
              autoFocus
            />
            <Button
              type="submit"
            >
              {status === 'setup' ? 'Complete MFA setup' : 'Sign in'}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </>
  );
}

export default RouteMFA;
