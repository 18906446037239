import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Register, { registerAction } from './register';
import Login, { loginAction } from './login';
import MFA, { mfaAction } from './mfa';

const router = createBrowserRouter([
  {
    path: '/',
    element: <h1>After My Death - Auth</h1>,
  },
  {
    path: '/register',
    element: <Register />,
    action: registerAction,
  },
  {
    path: '/login',
    element: <Login />,
    action: loginAction,
  },
  {
    path: '/mfa',
    element: <MFA />,
    action: mfaAction,
  },
]);

function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router;
