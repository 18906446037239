import React from 'react';
import { Form } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export { default as loginAction } from './action';

function RouteLogin() {
  return (
    <>
      <h1>After My Death - Login</h1>
      <Form method="post">
        <Stack spacing={2}>
          <TextField
            required
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
          >
            Sign in
          </Button>
        </Stack>
      </Form>
    </>
  );
}

export default RouteLogin;
